import React from "react";
import { Progress } from "reactstrap";
import StyledText from "../../components/Common/StyledText";

const OverAllBar = ({ totalSerialLogs, totalActiveEquipment }) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: 0,
            marginRight: 20,
          }}
        >
          <StyledText
            size={70}
            weight={700}
            color={"#0094ff"}
            style={{
              marginTop: -25,
              marginBottom: -25,
            }}
          >
            {totalSerialLogs}
          </StyledText>
          <StyledText
            size={16}
            style={{
              alignSelf: "flex-end",
              marginTop: -5,
            }}
          >
            Overall
          </StyledText>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="mt-4">
            <Progress
              color="info"
              value={totalSerialLogs}
              max={112}
              style={{
                height: 14,
                border: "#ccc solid 1px",
              }}
            />
            <StyledText
              size={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {totalSerialLogs}/{totalActiveEquipment}
            </StyledText>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OverAllBar;
