import rfidApi from "..";
import { USER_SERVICE } from "../constants";

class User {
  // fnGetUser = async (values) => {
  //   const payload = {
  //     display: values.display,
  //     page: values.page,
  //   };

  //   return await esignApi.post(USER_SERVICE.GET_USER_LIST, payload);
  // };

  // fnGetUserByID = async (values) => {
  //   return await esignApi.get(
  //     `${USER_SERVICE.GET_SELECTED_USER}/${values.id}`
  //   );
  // };

  // fnPostUser = async (payload) => {
  //   return await esignApi.post(USER_SERVICE.POST_USER_SUBMIT, payload);
  // };

  fnGetUserList = async (payload) => {
    return await rfidApi.get(USER_SERVICE.GET_USER_LIST);
  };

  fnAddNewUser = async (values) => {
    return await rfidApi.post(USER_SERVICE.ADD_NEW_USER, values);
  };

  fnUpdateUser = async (values) => {
    return await rfidApi.patch(
      `${USER_SERVICE.UPDATE_USER}/${values.id}`,
      values
    );
  };

  fnDeleteUser = async (id) => {
    return await rfidApi.delete(`${USER_SERVICE.DELETE_USER}/${id}`);
  };
}

User.api = new User();
export default User;
