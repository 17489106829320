import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionCreator, types } from "../../store";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

//breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { convertToYYYYMMDD } from "../../helpers/function_helper";


import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";


const AuditTrail = ({ audit, layout, ...props }) => {
  const { layoutMode } = layout;
  const defaultFontColor = layoutMode === "light" ? "#000000" : "#ced4da";
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: (params) => {
        return <span style={{ color: defaultFontColor }}>{params.row.id}</span>;
      },
    },
    {
      field: "location_name",
      headerName: "Location",
      width: 300,
      renderCell: (params) => {
        return (
          <span style={{ color: defaultFontColor }}>
            {params.row.location_name || "--"}
          </span>
        );
      },
    },
    {
      field: "serial_number",
      headerName: "Serial No.",
      width: 150,
      renderCell: (params) => {
        return (
          <span style={{ color: defaultFontColor }}>
            {params.row.serial_number || "--"}
          </span>
        );
      },
    },
    {
      field: "type",
      headerName: "IN/OUT",
      width: 200,
      renderCell: (params) => {
        return (
          <span style={{ color: defaultFontColor }}>
            {params.row.type || "--"}
          </span>
        );
      },
    },
    {
      field: "date_created",
      headerName: "Date Created",
      width: 200,
      renderCell: (params) => {
        return (
          <span style={{ color: defaultFontColor }}>
            {params.row.date_created || "--"}
          </span>
        );
      },
    },
  ];

  const [data, setData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(audit.data?.total_rows || 0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [modal, setModal] = React.useState(false);
  const [datePeriod, setDatePeriod] = React.useState('');

  const handleExportClicks = async () => {
    setModal(true);
  }

  const toggle = () => {
    setModal(false);
  };

  React.useEffect(() => {
    setData(audit.data?.result !== undefined ? audit.data?.result : []);
  }, [audit]);

  React.useEffect(() => {
    fetchAuditTrailList(paginationModel);
  }, [paginationModel]);

  React.useEffect(() => {
    setTotalRows((prevTotalRowState) =>
      audit.data?.total_rows !== undefined
        ? audit.data?.total_rows
        : prevTotalRowState
    );
  }, [audit.data?.total_rows, setTotalRows]);

  const fetchAuditTrailList = async (paginationModel) => {
    await props.actionCreator({
      type: types.GET_AUDIT_TRAIL_LIST,
      payload: {
        display: paginationModel.pageSize,
        page: paginationModel.page + 1,
      },
    });
  };

  const handleDatePeriod = async (value) => {
    setDatePeriod(value);
  }

  React.useEffect(() => {
    if (audit.error) {
      toast(audit.errorMessage, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
      });        
    } else {
      if (audit?.message) {
        toast(audit.message, {
          position: "top-right",
          hideProgressBar: true,
          className: "bg-success text-white",
        });
      }
    }
  }, [audit]);

  const handleExportAuditTrail = async () => {
    if (datePeriod === '') {
      toast('Please select date period.', {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
      });    
    } else {
      const dates = datePeriod.split(' to ');
      await props.actionCreator({
        type: types.EXPORT_AUDIT,
        payload: {
          from: convertToYYYYMMDD(dates[0]),
          to: convertToYYYYMMDD(dates[1]),
        },
      });
    }
  }

  //meta title
  document.title = "Audit Trail | RFID";

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Export Audit Trail
        </ModalHeader>
        <ModalBody>
            <Row>
              <Col xs={12}>
                <div className="mb-3">
                  <Label className="form-label">Date Period</Label>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="dd/mm/yyyy to dd/mm/yyyy"
                    value={datePeriod}
                    options={{
                      defaultDate: null,
                      mode: "range",
                      dateFormat: "d/m/Y",
                      onChange: (selectedDates, dateStr, instance) => {
                        handleDatePeriod(dateStr);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    onClick={() => {
                      handleExportAuditTrail();
                    }}
                    className="btn btn-success"
                  >
                    Export
                  </button>
                </div>
              </Col>
            </Row>
        </ModalBody>
      </Modal>



      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Audit Trail" breadcrumbItem="Audit Trail" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Audit Trail List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({totalRows})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleExportClicks}
                      >
                        <i className="bx bx-file me-1"></i> Export
                      </Link>
                    </div>
                  </div>
                </Col>

              </Row>

              {data.length > 0 && (
                <Row>
                  <Col xl="12">
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        columns={columns}
                        rows={data}
                        rowCount={totalRows}
                        loading={audit.loading}
                        pageSizeOptions={[10, 20, 30, 40, 50, 80, 100]}
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        style={{ minHeight: 100 }}
                        localeText={{ noRowsLabel: audit.errorMessage || "" }}
                      />
                    </Box>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ audit, layout }) => ({
  audit,
  layout,
});

export default connect(mapStateToProps, { actionCreator })(AuditTrail);
