import React, { useState } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import Layout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import Error404 from "./pages/Utility/Error404";
import Login from "./pages/Authentication/Login";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import RFIDDashboard from "./pages/RFIDDashboard";

// Activating fake backend
fakeBackend();

const App = ({ authentication, app, user, dashboard, audit, commission, equipment, ...props }) => {
  return (
    <React.Fragment>
      {/* <Router> */}
      <Routes>
        {!authentication.authenticated && (
          <Route path="/login" element={<Login />} />
        )}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              authentication.authenticated &&
              authentication.data.data.accessUrl
                .map((item) => item.url)
                .includes(route.path) ? (
                <Authmiddleware path={route.path}>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              ) : (
                <Navigate to="/login" />
              )
            }
            key={idx}
            exact={true}
          />
        ))}
        <Route path="*" element={<Navigate to="/pages-404" replace />} />
      </Routes>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication, app, user, dashboard, audit, commission, equipment, ...state }) => {
  return {
    layout: state.layout,
    authentication,
    app,
    user,
    dashboard,
    audit,
    commission,
    equipment
  };
};

export default connect(mapStateToProps, {})(App);
