export const APP_SERVICE = {
  MENU: "api/user-group/menu-list",
};

export const USER_SERVICE = {
  LOGIN: "api/user/login",
  GET_USER_LIST: "api/user/list",
  ADD_NEW_USER: "api/user/add",
  UPDATE_USER: "api/user/update",
  DELETE_USER: "api/user/delete",
  REQUEST_RESET_PASSWORD: "api/user/request-reset-password",
  CHANGE_PASSWORD: "api/user/change-password",
};

export const DOCUMENT_SERVICE = {
  GET_DOCUMENTS: "api/documents/view-all",
  GET_DOCUMENTS_LIST: "api/documents/list",
  GET_SELECTED_DOCUMENTS: "api/documents/view",
  POST_DOCUMENT: "api/documents/add",
  PUT_DOCUMENT: "api/documents/edit",
  GET_PDF_BYTES: "api/document/pdf",
};

export const PROFILE_SERVICE = {
  GET_PROFILE: "api/user-profile/view",
  //GET_SELECTED_INBOX: "api/inbox/view",
  //POST_INBOX_SUBMIT: "api/inbox/submit",
  EDIT_PROFILE: "api/user-profile/update",
};

export const USERGROUP_SERVICE = {
  GET_USERGROUP: "api/user-group/view-all",
  GET_USERGROUP_LIST: "api/user-group/list",
  GET_SELECTED_USERGROUP: "api/user-group/view",
  POST_USERGROUP_SUBMIT: "api/user-group/submit",
  ADD_NEW_USERGROUP: "api/user-group/add",
  UPDATE_USERGROUP: "api/user-group/update",
  DELETE_USERGROUP: "api/user-group/delete",
};

export const DASHBOARD_SERVICE = {
  GET_SERIAL_LOGS: "api/gateway/logs",
  GET_AUDIT_TRAILS: "api/gateway/audit-trail",
  GET_AUDIT_TRAIL_LIST: "api/audit/list",
  EXPORT_AUDIT: "api/audit/export",
  CHECK_MISMATCH: "api/gateway/check_mismatch",
};

export const COMMISSION_SERVICE = {
  GET_COMMISSION_LIST: "api/commission/list",
  ADD_NEW_COMMISSION: "api/commission/add",
  UPDATE_COMMISSION: "api/commission/update",
  DELETE_COMMISSION: "api/commission/delete",
  EXPORT_COMMISSION: "api/commission/export",
};

export const EQUIPMENT_SERVICE = {
  GET_EQUIPMENT_LIST: "api/equipment/list",
  ADD_NEW_EQUIPMENT: "api/equipment/add",
  UPDATE_EQUIPMENT: "api/equipment/update",
  DELETE_EQUIPMENT: "api/equipment/delete",
};

export const LOCATION_SERVICE = {
  GET_LOCATION_LIST: "api/location/list",
  ADD_NEW_LOCATION: "api/location/add",
  UPDATE_LOCATION: "api/location/update",
  DELETE_LOCATION: "api/location/delete",
};
