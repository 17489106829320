import rfidApi from "../../api";
import { USER_SERVICE } from "../../api/constants";

class Authentication {
  async fnLogin (values) {
    const payload = {
      username: values.username,
      password: values.password,
    };
    return await rfidApi.post(USER_SERVICE.LOGIN, payload);
  };

  async fnForgetPassword (values) {
    const payload = {
      email: values.email,
    };
    return await rfidApi.post(USER_SERVICE.REQUEST_RESET_PASSWORD, payload);
  };

  async fnResetPassword (values) {
    const payload = {
      password: values.password,
    };
    return await rfidApi.post(USER_SERVICE.CHANGE_PASSWORD, payload);
  };
}

Authentication.api = new Authentication();
export default Authentication;
