import React from 'react';
import withRouter from "../components/Common/withRouter"

const NonAuthLayout = (props) => {
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

export default withRouter(NonAuthLayout);
