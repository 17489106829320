import {
  INITIAL_AUTHENTICATION,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CONNECTION_ERROR
} from "./types";

const initialState = {
  authenticated: false,
  sessionTimeout: false,
  token: null,
  loading: true,
  data: [],
  error: null,
  errorMessage: null,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_AUTHENTICATION:
      state = {
        ...state,
        loading: false,
        token: action.payload.token,
        authenticated: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        authenticated: true,
        data: action.payload,
        token: action.payload.token,
        error: null,
        errorMessage: null,
      };
      break;
    case LOGIN_FAILED:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
        authenticated: false,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        error: null,
        authenticated: false,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = initialState;
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case CONNECTION_ERROR:
      state = {
        ...state,
        error: true,
        errorMessage: "Server connection failed.",
        status: 502,
      };
      break;
    default:
      state = state;

      break;
  }
  return state;
};

export default authentication;
