import rfidApi from "..";
import { DASHBOARD_SERVICE } from "../constants";

class Tag {
  async fnCheckTagMismatch(values) {
    const payload = {
      serial_number: values.serial_number
    };
  
    return await rfidApi.post(DASHBOARD_SERVICE.CHECK_MISMATCH, payload);
  }
}

Tag.api = new Tag();
export default Tag;
