import React from "react";
import { connect } from "react-redux";

const StyledText = ({
  size = 14,
  color = "",
  weight,
  style = {},
  layout,
  ...rest
}) => {
  const { layoutMode } = layout;
  const defaultFontColor = layoutMode === "light" ? "#000000" : "#ced4da";
  return (
    <label
      {...rest}
      style={{
        fontSize: size,
        color: color ? color : defaultFontColor,
        fontWeight: weight,
        ...style,
      }}
    />
  );
};

StyledText.defaultProps = {
  size: 14,
  weight: "normal",
};

const mapStateToProps = ({ layout }) => ({
  layout,
});

export default connect(mapStateToProps, {})(StyledText);
