import {
  CHECK_TAGS_MISMATCH_SUCCESS,
  CHECK_TAGS_MISMATCH_FAILED,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
};

const tag = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_TAGS_MISMATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case CHECK_TAGS_MISMATCH_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default tag;
