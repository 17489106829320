export const GET_COMMISSION_LIST = "@commission/GET_COMMISSION_LIST";
export const GET_COMMISSION_LIST_SUCCESS =
  "@commission/GET_COMMISSION_LIST_SUCCESS";
export const GET_COMMISSION_LIST_FAILED =
  "@commission/GET_COMMISSION_LIST_FAILED";

export const ADD_NEW_COMMISSION = "@commission/ADD_NEW_COMMISSION";
export const ADD_NEW_COMMISSION_SUCCESS =
  "@commission/ADD_NEW_COMMISSION_SUCCESS";
export const ADD_NEW_COMMISSION_FAILED =
  "@commission/ADD_NEW_COMMISSION_FAILED";

export const UPDATE_COMMISSION = "@commission/UPDATE_COMMISSION";
export const UPDATE_COMMISSION_SUCCESS =
  "@commission/UPDATE_COMMISSION_SUCCESS";
export const UPDATE_COMMISSION_FAILED = "@commission/UPDATE_COMMISSION_FAILED";

export const DELETE_COMMISSION = "@commission/DELETE_COMMISSION";
export const DELETE_COMMISSION_SUCCESS =
  "@commission/DELETE_COMMISSION_SUCCESS";
export const DELETE_COMMISSION_FAILED = "@commission/DELETE_COMMISSION_FAILED";
export const CLEAR_COMMISSION = "@commission/CLEAR_COMMISSION";
export const CLEAR_COMMISSION_ERROR_MESSAGE = "@commission/CLEAR_COMMISSION_ERROR_MESSAGE";


export const EXPORT_COMMISSION = "@commission/EXPORT_COMMISSION";
export const EXPORT_COMMISSION_SUCCESS =
  "@commission/EXPORT_COMMISSION_SUCCESS";
export const EXPORT_COMMISSION_FAILED = "@commission/EXPORT_COMMISSION_FAILED";
