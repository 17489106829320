import {
  GET_AUDIT_TRAIL_LIST_SUCCESS,
  GET_AUDIT_TRAIL_LIST_FAILED,
  EXPORT_AUDIT_SUCCESS,
  EXPORT_AUDIT_FAILED,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
};

const audit = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_AUDIT_TRAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_AUDIT_TRAIL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case EXPORT_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        message: action.payload.message,
      };

    case EXPORT_AUDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default audit;
