import React from "react";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import StyledText from "../../../components/Common/StyledText";

const formatHoursMinutes = (dateTimeString) => {
  const momentDate = moment.utc(dateTimeString);
  const timeString = momentDate.format("HH:mm");

  return timeString;
};

const AuditTrail = ({ auditLogs }) => {
  return (
    <React.Fragment>
      <div className="col-md-3">
        <StyledText size={18} weight={700}>
          Audit Trail
        </StyledText>
        {auditLogs.map((item, index) => (
          <div key={index} style={{ marginTop: 5, marginBottom: 5 }}>
            <StyledText size={14} weight={700} style={{ marginRight: 5 }}>
              {item.location_name} -{" "}
            </StyledText>
            <StyledText size={13} style={{ marginRight: 5 }}>
              {item.serial_number}
            </StyledText>
            <StyledText
              size={13}
              color={item.type === "IN" ? "#00B127" : "#D10D0D"}
              weight={700}
            >
              {" "}
              {item.type}{" "}
            </StyledText>
            <StyledText size={13}>
              : {formatHoursMinutes(item.date_created)}
            </StyledText>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default AuditTrail;
