import { call, put, takeLatest } from "redux-saga/effects";
import saveAs from "file-saver";

import { handleErrorResponse } from "../../helpers/api_helper"
import {
  GET_COMMISSION_LIST,
  GET_COMMISSION_LIST_SUCCESS,
  GET_COMMISSION_LIST_FAILED,
  ADD_NEW_COMMISSION,
  ADD_NEW_COMMISSION_SUCCESS,
  ADD_NEW_COMMISSION_FAILED,
  UPDATE_COMMISSION,
  UPDATE_COMMISSION_SUCCESS,
  UPDATE_COMMISSION_FAILED,
  DELETE_COMMISSION,
  DELETE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_FAILED,
  EXPORT_COMMISSION,
  EXPORT_COMMISSION_SUCCESS,
  EXPORT_COMMISSION_FAILED,
} from "./types";

import commissionServices from "../../api/services/commission";

function* fnGetCommissionList() {
  try {
    const data = yield call(commissionServices.api.fnGetCommissionList);

    yield put({
      type: GET_COMMISSION_LIST_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_COMMISSION_LIST_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnAddNewCommission({ payload }) {
  try {
    const result = yield call(
      commissionServices.api.fnAddNewCommission,
      payload
    );

    if (result) {
      yield put({
        type: ADD_NEW_COMMISSION_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_COMMISSION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_NEW_COMMISSION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnUpdateCommission({ payload }) {
  try {
    const result = yield call(
      commissionServices.api.fnUpdateCommission,
      payload
    );

    if (result) {
      yield put({
        type: UPDATE_COMMISSION_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_COMMISSION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_COMMISSION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnDeleteCommission({ payload }) {
  try {
    const { id } = payload;

    const result = yield call(commissionServices.api.fnDeleteCommission, id);

    if (result) {
      yield put({
        type: DELETE_COMMISSION_SUCCESS,
        payload: { message: result.data.message },
      });

      yield put({
        type: GET_COMMISSION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_COMMISSION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnExportCommission({ payload }) {
  try {
    const result = yield call(
      commissionServices.api.fnExportCommission,
      payload
    );

    if (result) {
      const filename = `Commission_List.xlsx`;
      const buffer = result.data.buffer.data;
      const arraybuffer = Uint8Array.from(buffer).buffer;
      const blob = new Blob([arraybuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, filename);
      yield put({
        type: EXPORT_COMMISSION_SUCCESS,
        payload: { message: result.data.message },
      });
    }
  } catch (error) {
    yield put({
      type: EXPORT_COMMISSION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}


export default function* watcher() {
  yield takeLatest(GET_COMMISSION_LIST, fnGetCommissionList);
  yield takeLatest(ADD_NEW_COMMISSION, fnAddNewCommission);
  yield takeLatest(UPDATE_COMMISSION, fnUpdateCommission);
  yield takeLatest(DELETE_COMMISSION, fnDeleteCommission);
  yield takeLatest(EXPORT_COMMISSION, fnExportCommission);
}
