import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";
import ErrorModal from "../../components/Common/ErrorModal";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Identifier, Date } from "./column";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

const Locations = ({ locations, ...props }) => {
  const [selectedLocation, setSelectedLocation] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      location_name: (selectedLocation && selectedLocation.location_name) || "",
      identifier: (selectedLocation && selectedLocation.identifier) || "",
      status: (selectedLocation && selectedLocation.status) || "",
    },
    validationSchema: Yup.object({
      location_name: Yup.string().required("Please enter location name."),
      identifier: Yup.string()
        .required("Please enter Identifier.")
        .max(10, "At most 10 characters only."),
      status: Yup.string().required("Please select status."),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateLocation = {
          id: selectedLocation.id,
          location_name: values.location_name,
          identifier: values.identifier,
          status: parseInt(values.status),
        };

        // update location
        props.actionCreator({
          type: types.UPDATE_LOCATION,
          payload: updateLocation,
        });

        validation.resetForm();
        setIsEdit(false);
      } else {
        const newLocation = {
          location_name: values["location_name"],
          identifier: values["identifier"],
          status: parseInt(values["status"]),
        };

        // save new location
        props.actionCreator({
          type: types.ADD_NEW_LOCATION,
          payload: newLocation,
        });

        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        Cell: (location) => {
          return location.row.original.id;
        },
      },
      {
        Header: "Location",
        accessor: "location_name",
        width: "20%",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (location) => {
          return location.row.original.location_name;
        },
      },
      {
        Header: "Identifier",
        filterable: false,
        disableFilters: true,
        accessor: "identifier",
        Cell: (cellProps) => {
          return <Identifier {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (location) =>
          location.row.original.status === 1 ? "Active" : "Inactive",
      },
      {
        Header: "Date Modified",
        accessor: "date_modified",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const locationData = cellProps.row.original;
                    handleLocationClick(locationData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_LOCATION });
    toast(locations.message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_LOCATION_ERROR });
    toast(locations.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  React.useEffect(() => {
    locations.isLocationSubmitted && handleNotification();
    locations.error &&
      locations.errorMessage &&
      locations.status != 500 &&
      locations.status != 403 &&
      handleErrorNotification();
  }, [locations]);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_LOCATION_LIST,
    });
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleLocationClick = (arg) => {
    const location = arg;

    setSelectedLocation({
      id: location.id,
      location_name: location.location_name,
      identifier: location.identifier,
      status: location.status,
    });

    setIsEdit(true);

    toggle();
  };

  const node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete location modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (location) => {
    setSelectedLocation(location);
    setDeleteModal(true);
  };

  const handleDeleteLocation = () => {
    props.actionCreator({
      type: types.DELETE_LOCATION,
      payload: {
        id: selectedLocation.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleLocationClicks = () => {
    setSelectedLocation({});
    setIsEdit(false);
    toggle();
  };

  //meta title
  document.title = "Locations | RFID";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLocation}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={locations}
        statusCode={locations.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={locations.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Locations" breadcrumbItem="Locations" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Location List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({locations.data.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleLocationClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>

                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                        tag="a"
                      >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={locations.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Location" : "New Location"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Location Name
                              </Label>
                              <Input
                                name="location_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.location_name || ""}
                                invalid={
                                  validation.touched.location_name &&
                                  validation.errors.location_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.location_name &&
                              validation.errors.location_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.location_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Identifier</Label>
                              <br />
                              <span style={{ fontSize: 12 }}>
                                Identifier name will be displayed in the
                                dashboard.
                              </span>
                              <br />
                              <br />
                              <Input
                                name="identifier"
                                label="Identifier"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.identifier || ""}
                                invalid={
                                  validation.touched.identifier &&
                                  validation.errors.identifier
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.identifier &&
                              validation.errors.identifier ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.identifier}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                type="select"
                                name="status"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || "0"}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ locations }) => ({
  locations,
});

export default connect(mapStateToProps, { actionCreator })(Locations);
