export const GET_USER_LIST = "@user/GET_USER_LIST";
export const GET_USER_LIST_SUCCESS ="@user/GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED ="@user/GET_USER_LIST_FAILED";

export const ADD_NEW_USER = "@user/ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS ="@user/ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILED ="@user/ADD_NEW_USER_FAILED";

export const UPDATE_USER = "@user/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "@user/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "@user/UPDATE_USER_FAILED";

export const DELETE_USER = "@user/DELETE_USER";
export const DELETE_USER_SUCCESS = "@user/DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "@user/DELETE_USER_FAILED";
export const CLEAR_USER = "@user/CLEAR_USER";
export const CLEAR_USER_ERROR = "@user/CLEAR_USER_ERROR";

