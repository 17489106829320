import React from 'react';
import { connect } from 'react-redux';
import { Container, UncontrolledAlert } from 'reactstrap';
import { actionCreator, types } from '../../store';

import Breadcrumb from '../../components/Common/Breadcrumb';
import OverAllBar from './OverAllBar';
import AuditTrail from './AuditTrail';
import SerialLiveLocation from './SerialLiveLocation';
import ErrorModal from '../../components/Common/ErrorModal';

const RFIDDashboard = ({
  dashboard,
  authentication,
  user,
  equipment,
  tag,
  commission,
  ...props
}) => {
  const [ws, setWs] = React.useState(null);
  const [liveSerialLogsSource, setLiveSerialLogsSource] = React.useState([]);
  const [overall, setOverAll] = React.useState(0);
  const [errorModal, setErrorModal] = React.useState(false);
  const [mismatch, setMismatch] = React.useState([]);
  React.useEffect(() => {
    initiateWebSocketConnection();
    getLiveSerialLogs();
    getAuditTrail();
    getActiveEquipment();
    // Cleanup function to close WebSocket connection when component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  React.useEffect(() => {
    dashboard.serialLogs.data.length > 0 && setSerialLogsData();
  }, [dashboard]);

  React.useEffect(() => {
    console.log('tag.data]', tag.data);

    if (tag.data.serial_number) {
      JSON.parse(tag.data.missing_tags) &&
        JSON.parse(tag.data.missing_tags).length > 0 &&
        setMismatch((prev) => {
          let newData = [...prev];
          newData.push({
            serial: tag.data.serial_number,
            missing_tags: JSON.parse(tag.data.missing_tags),
          });
          return newData;
        });
    }
  }, [tag.data]);

  React.useEffect(() => {
    getOverAllTotal();
  }, [liveSerialLogsSource]);

  const getOverAllTotal = () => {
    let total = 0;
    liveSerialLogsSource.map((item) => {
      total = total + item.serials.length;
    });
    setOverAll(total);
  };

  const getLiveSerialLogs = async () => {
    await props.actionCreator({
      type: types.GET_SERIAL_LOGS,
    });
  };

  const getAuditTrail = async () => {
    await props.actionCreator({
      type: types.GET_AUDIT_TRAIL,
      payload: {
        limit: 10,
      },
    });
  };

  const getActiveEquipment = async () => {
    await props.actionCreator({
      type: types.GET_EQUIPMENT_LIST,
    });
    props.actionCreator({
      type: types.GET_COMMISSION_LIST,
    });
  };

  const checkTagsIsComplete = async (serial_number) => {
    await props.actionCreator({
      type: types.CHECK_TAGS_MISMATCH,
      payload: {
        serial_number,
      },
    });
  };

  const setSerialLogsData = () => {
    const result = regroupByLocation(dashboard.serialLogs.data);

    setLiveSerialLogsSource(result);
  };

  const regroupByLocation = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const { location_name, serial_number } = item;
      if (!groupedData[location_name]) {
        groupedData[location_name] = {
          location_name: location_name,
          serials: [],
        };
      }
      if (serial_number) {
        groupedData[location_name].serials.push({
          serial_number,
          has_missing_tags:
            item.missing_tags && JSON.parse(item.missing_tags).length > 0,
        });
      }
    });

    // Convert object to array
    const dataArray = Object.values(groupedData);

    // Sort the array by location_name alphabetically
    dataArray.sort((a, b) => a.location_name.localeCompare(b.location_name));

    return dataArray;
  };

  const today = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = today.getFullYear();
    const dateString = `${day}/${month}/${year}`;

    return dateString;
  };

  const initiateWebSocketConnection = () => {
    const socket = new WebSocket('ws://localhost:8080');

    socket.addEventListener('open', () => {
      console.log('...');
      setWs(socket);
    });

    socket.addEventListener('message', (event) => {
      getAuditTrail();
      console.log('Received:', event.data);

      const data = JSON.parse(event.data);
      if (Object.keys(data).length !== 0 && data.type == 'IN') {
        data.serial_number;
        checkTagsIsComplete(data.serial_number);
      }

      getLiveSerialLogs();
    });

    socket.addEventListener('close', () => {
      console.log('xxxx');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  //meta title
  document.title = 'Dashboard | RFID';
  return (
    <React.Fragment>
      <ErrorModal
        component={equipment}
        statusCode={equipment.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={equipment.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div
        className="page-content"
        style={{
          marginBottom: 30,
        }}
      >
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Dashboard" />
          {mismatch &&
            mismatch?.map((item, index) => (
              <UncontrolledAlert
                color="danger"
                key={'_alert_mismatch_' + index}
              >
                <i className="mdi mdi-block-helper me-2"></i>
                {item.serial} - Mismatch Tag{' '}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  ({item.missing_tags.join(', ')})
                </span>
              </UncontrolledAlert>
            ))}
          <div
            className="rfid-container col-md-12"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="analytics-container col-md-4"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingTop: 25,
              }}
            >
              <h5
                className="card-title"
                style={{
                  fontSize: 20,
                  marginRight: 20,
                  marginTop: -5,
                }}
              >
                Analytics
              </h5>
              <i className="dripicons-calendar"> {today()}</i>
            </div>
            <OverAllBar
              totalSerialLogs={overall}
              totalActiveEquipment={
                commission?.data &&
                commission?.data.filter((item) => item.status === 1).length
              }
            />
          </div>
          <div
            className="audit-container"
            style={{
              display: 'flex',
              marginTop: 20,
            }}
          >
            <SerialLiveLocation liveSerialLogsSource={liveSerialLogsSource} />
            <AuditTrail auditLogs={dashboard.auditLogs.data} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  dashboard,
  authentication,
  user,
  equipment,
  tag,
  commission,
}) => ({
  dashboard,
  authentication,
  user,
  equipment,
  tag,
  commission,
});

export default connect(mapStateToProps, { actionCreator })(RFIDDashboard);
