import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logo from "../../assets/images/logo-sm.svg";
import stEngrLogo from "../../assets/images/ste-logo.png";

import { LOGIN_USER } from "../../store/authentication/types";

import { actionCreator } from "../../store";

//Import config
import config from "../../config";

const Login = ({ authentication, ...props }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      props.actionCreator({
        type: LOGIN_USER,
        payload: {
          user: values,
          history: props.router.navigate,
        },
      });
    },
  });

  React.useEffect(() => {
    // notify after error
    if (
      authentication.error?.code == "ERR_NETWORK" &&
      authentication.error?.response === undefined
    ) {
      setErrorMessage("Server connection failed.");
    } else {
      if (authentication.error && authentication.error?.response) {
        setErrorMessage(authentication.error.response?.data?.message);
      }
    }

    if (authentication.authenticated) {
      navigate(authentication.data.data.accessUrl[0].url);
    }
    setLoading(false);
  }, [authentication]);

  document.title = "Login | RFID";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3 mx-auto">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-1 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={stEngrLogo} alt="" height="28" />{" "}
                        <span className="logo-txt">RFID</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className=" text-center">
                          {errorMessage ? (
                            errorMessage === "password_expired" ? (
                              <Alert color="danger">
                                The password for this account has expired.
                                Kindly proceed to reset your password using the
                                provided{" "}
                                <Link
                                  to={authentication.error.response?.data?.data.url}
                                  className="fw-bold"
                                >link
                                </Link>
                                .
                              </Alert>
                            ) : (
                              <Alert color="danger">{errorMessage}</Alert>
                            )
                          ) : (
                            <div style={{ height: "63px" }}>&nbsp;</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            className="form-control"
                            type="username"
                            //onChange={validation.handleChange}
                            onChange={(value) => {
                              validation.handleChange(value);
                              setErrorMessage("");
                            }}
                            onBlur={(value) => {
                              validation.handleBlur(value);
                              setErrorMessage("");
                            }}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">Password</Label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i
                                className={`mdi ${!passwordShow ? "mdi-eye-off-outline" : "mdi-eye-outline"}`}
                              ></i>
                            </button>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Loading..." : "Log In"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ST Engineering.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication }) => ({ authentication });

export default withRouter(connect(mapStateToProps, { actionCreator })(Login));
