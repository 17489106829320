export const GET_EQUIPMENT_LIST = "@equipment/GET_EQUIPMENT_LIST";
export const GET_EQUIPMENT_LIST_SUCCESS =
  "@equipment/GET_EQUIPMENT_LIST_SUCCESS";
export const GET_EQUIPMENT_LIST_FAILED = "@equipment/GET_EQUIPMENT_LIST_FAILED";

export const ADD_NEW_EQUIPMENT = "@equipment/ADD_NEW_EQUIPMENT";
export const ADD_NEW_EQUIPMENT_SUCCESS = "@equipment/ADD_NEW_EQUIPMENT_SUCCESS";
export const ADD_NEW_EQUIPMENT_FAILED = "@equipment/ADD_NEW_EQUIPMENT_FAILED";

export const UPDATE_EQUIPMENT = "@equipment/UPDATE_EQUIPMENT";
export const UPDATE_EQUIPMENT_SUCCESS = "@equipment/UPDATE_EQUIPMENT_SUCCESS";
export const UPDATE_EQUIPMENT_FAILED = "@equipment/UPDATE_EQUIPMENT_FAILED";

export const DELETE_EQUIPMENT = "@equipment/DELETE_EQUIPMENT";
export const DELETE_EQUIPMENT_SUCCESS = "@equipment/DELETE_EQUIPMENT_SUCCESS";
export const DELETE_EQUIPMENT_FAILED = "@equipment/DELETE_EQUIPMENT_FAILED";
export const CLEAR_EQUIPMENT = "@equipment/CLEAR_EQUIPMENT";
export const CLEAR_EQUIPMENT_ERROR_MESSAGE = "@equipment/CLEAR_EQUIPMENT_ERROR_MESSAGE";