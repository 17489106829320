import {
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILED,
  ADD_NEW_LOCATION_SUCCESS,
  ADD_NEW_LOCATION_FAILED,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILED,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILED,
  CLEAR_LOCATION,
  CLEAR_LOCATION_ERROR
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
  status: null,
  isLocationSubmitted: false,
};

const locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        status: null,
      };

    case GET_LOCATION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        status: action.status,
      };

    case ADD_NEW_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isLocationSubmitted: true,
        message: action.payload.message,
      };

    case ADD_NEW_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isLocationSubmitted: false,
      };

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isLocationSubmitted: true,
        message: action.payload.message,
      };

    case UPDATE_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isLocationSubmitted: false,
      };

    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isLocationSubmitted: true,
        message: action.payload.message,
      };

    case DELETE_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isLocationSubmitted: false,
      };
    case CLEAR_LOCATION:
      return INIT_STATE;
    case CLEAR_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        status: null,
      };
    default:
      return state;
  }
};

export default locations;
