import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Status, Date, Type } from "./column";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import ErrorModal from "../../components/Common/ErrorModal";

const EquipmentList = ({ equipment, ...props }) => {
  document.title = "User List | RFID";

  const [selectedEquipment, setSelectedEquipment] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (selectedEquipment && selectedEquipment.name) || "",
      status: (selectedEquipment && selectedEquipment.status) || "",
      type: (selectedEquipment && selectedEquipment.type) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      status: Yup.string().required("Please Select Status"),
      type: Yup.string().required("Please Select Type"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEquipment = {
          id: selectedEquipment.id,
          name: values.name,
          status: parseInt(values.status),
          type: parseInt(values.type),
        };

        // update equipment
        props.actionCreator({
          type: types.UPDATE_EQUIPMENT,
          payload: updateEquipment,
        });
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newEquipment = {
          name: values["name"],
          status: parseInt(values["status"]),
          type: parseInt(values["type"]),
        };

        // save new equipment
        props.actionCreator({
          type: types.ADD_NEW_EQUIPMENT,
          payload: newEquipment,
        });
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        Cell: (location) => {
          return location.row.original.id;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.row.original.name || "--";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Date Modified",
        accessor: "date_modified",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const commissionData = cellProps.row.original;
                    handleCommissionClick(commissionData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_EQUIPMENT });
    toast(equipment.message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_COMMISSION_ERROR_MESSAGE });
    toast(equipment.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  React.useEffect(() => {
    equipment.isEquipmentSubmitted && handleNotification();

    equipment.error &&
      equipment.errorMessage &&
      equipment.status != 500 &&
      equipment.status != 403 &&
      handleErrorNotification();
  }, [equipment]);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_EQUIPMENT_LIST,
    });
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleCommissionClick = (arg) => {
    const equipment = arg;

    setSelectedEquipment({
      id: equipment.id,
      name: equipment.name,
      status: equipment.status,
      type: equipment.type,
    });
    setIsEdit(true);

    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete equipment modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (equipment) => {
    setSelectedEquipment(equipment);
    setDeleteModal(true);
  };

  const handleDeleteEquipment = () => {
    props.actionCreator({
      type: types.DELETE_EQUIPMENT,
      payload: {
        id: selectedEquipment.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleEquipmentClicks = () => {
    setSelectedEquipment({});
    setIsEdit(false);
    toggle();
  };

  //meta title
  document.title = "Equipment | RFID";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEquipment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={equipment}
        statusCode={equipment.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={equipment.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Equipment" breadcrumbItem="Equipment" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Equipment List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({equipment.data?.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleEquipmentClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>

                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                        tag="a"
                      >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={equipment.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Equipment" : "Add Equipment"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Type</Label>
                              <Input
                                type="select"
                                name="type"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type &&
                                  validation.errors.type
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>
                                  Select Weapon/Optical
                                </option>
                                <option value={1}>Weapon</option>
                                <option value={2}>Optical</option>
                              </Input>
                              {validation.touched.type &&
                              validation.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                label="Name."
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                type="select"
                                name="status"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || "0"}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ equipment }) => ({
  equipment,
});

export default connect(mapStateToProps, { actionCreator })(EquipmentList);
