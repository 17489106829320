import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_AUDIT_TRAIL_LIST,
  GET_AUDIT_TRAIL_LIST_SUCCESS,
  GET_AUDIT_TRAIL_LIST_FAILED,
  EXPORT_AUDIT,
  EXPORT_AUDIT_SUCCESS,
  EXPORT_AUDIT_FAILED
} from "./types";

import auditServices from "../../api/services/audit";

import { handleErrorResponse } from "../../helpers/api_helper"

function* fnGetAuditTrailList({ payload }) {
  const { display, page } = payload;
  try {
    const response = yield call(auditServices.api.fnGetAuditTrailList, {
      display,
      page,
    });

    yield put({
      type: GET_AUDIT_TRAIL_LIST_SUCCESS,
      payload: {
        ...response.data.result,
      },
    });
  } catch (error) {
    yield put({
      type: GET_AUDIT_TRAIL_LIST_FAILED,
      payload: setServerErrorResponse(error),
    });
  }
}

function* fnExportAudit( { payload }) {
  try {
    const result = yield call(
      auditServices.api.fnExportAudit,
      payload
    );

    if (result) {
      if (!result.data.success) {
        yield put({
          type: EXPORT_AUDIT_FAILED, 
          payload: { message: result.data.message },
        });
      } else {
        const filename = `Audit_List.xlsx`;
        const buffer = result.data.buffer.data;
        const arraybuffer = Uint8Array.from(buffer).buffer;
        const blob = new Blob([arraybuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, filename);

        yield put({
          type: EXPORT_AUDIT_SUCCESS, 
          payload: { message: result.data.message },
        });
      }
    }
  } catch (error) {
    yield put({
      type: EXPORT_AUDIT_FAILED,
      ...handleErrorResponse(error)
    });
  }
}


export const setServerErrorResponse = (error) =>
  error.response !== undefined
    ? error.response.data
    : {
        result: false,
        message: "Oops, something went wrong. Please try again later.",
      };

export default function* watcher() {
  yield takeLatest(GET_AUDIT_TRAIL_LIST, fnGetAuditTrailList);
  yield takeLatest(EXPORT_AUDIT, fnExportAudit);
}
