import { GET_MENU_SUCCESS, GET_MENU_FAILED, CLEAR_MENU } from "./types";

const INITIAL_STATE = {
  loading: true,
  data: [],
  error: null,
  errorMessage: null,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.menu,
        error: null,
        errorMessage: null,
      };
    case GET_MENU_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.message,
      };
    case CLEAR_MENU:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default app;
