import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Username,
  Firstname,
  Lastname,
  Email,
  Position,
  Status,
  Date,
} from "./column";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import ErrorModal from "../../components/Common/ErrorModal";

import { isEmpty } from "lodash";

const User = ({ user, usergroup, authentication, ...props }) => {
  document.title = "User List | RFID";
  const [passwordShow, setPasswordShow] = useState(false);
  const [selectedUser, setSelectedUser] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const passwordRules =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/;
  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});
  const [errorModal, setErrorModal] = React.useState(false);

  React.useEffect(() => {
    if (isEdit) {
      setDynamicOptionsValue({
        username: (selectedUser && selectedUser.username) || "",
        firstname: (selectedUser && selectedUser.firstname) || "",
        lastname: (selectedUser && selectedUser.lastname) || "",
        email: (selectedUser && selectedUser.email) || "",
        position: (selectedUser && selectedUser.position) || "",
        password: (selectedUser && selectedUser.password) || "",
        user_group_id: (selectedUser && selectedUser.user_group_id) || "",
        is_active: (selectedUser && selectedUser.is_active) || "",
      });
      setDynamicOptionsValue2({
        username: Yup.string().required("Username is required"),
        firstname: Yup.string().required("Firstname is required"),
        lastname: Yup.string().required("Lastname is required"),
        email: Yup.string().email().required("Email is required"),
        password: Yup.string()
          .matches(passwordRules, {
            message:
              "Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character",
          })
          .matches(/^\S*$/, "White Spaces are not allowed"),
        //  .required("Password is required"),
        user_group_id: Yup.string().required("Please Select User Group"),
        is_active: Yup.string().required("Please Select Status"),
      });
    } else {
      setDynamicOptionsValue({
        username: (selectedUser && selectedUser.username) || "",
        firstname: (selectedUser && selectedUser.firstname) || "",
        lastname: (selectedUser && selectedUser.lastname) || "",
        email: (selectedUser && selectedUser.email) || "",
        position: (selectedUser && selectedUser.position) || "",
        password: (selectedUser && selectedUser.password) || "",
        user_group_id: (selectedUser && selectedUser.user_group_id) || "",
        is_active: (selectedUser && selectedUser.is_active) || "",
      });
      setDynamicOptionsValue2({
        username: Yup.string().required("Username is required"),
        firstname: Yup.string().required("Firstname is required"),
        lastname: Yup.string().required("Lastname is required"),
        email: Yup.string().email().required("Email is required"),
        password: Yup.string()
          .matches(passwordRules, {
            message:
              "Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character",
          })
          .matches(/^\S*$/, "White Spaces are not allowed")
          .required("Password is required"),
        user_group_id: Yup.string().required("Please Select User Group"),
        is_active: Yup.string().required("Please Select Status"),
      });
    }
  }, [isEdit, selectedUser]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: selectedUser.id,
          username: values.username,
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          position: values.position,
          password: values.password,
          user_group_id: parseInt(values.user_group_id),
          is_active: parseInt(values.is_active),
        };
        // update user
        props.actionCreator({
          type: types.UPDATE_USER,
          payload: updateUser,
        });
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          username: values["username"],
          firstname: values["firstname"],
          lastname: values["lastname"],
          email: values["email"],
          position: values["position"],
          password: values["password"],
          user_group_id: parseInt(values["user_group_id"]),
          is_active: parseInt(values["is_active"]),
        };

        // save new user
        props.actionCreator({
          type: types.ADD_NEW_USER,
          payload: newUser,
        });
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        width: "20%",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Username {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Firstname",
        accessor: "firstname",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Firstname {...cellProps} />;
        },
      },
      {
        Header: "Lastname",
        accessor: "lastname",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Lastname {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Position",
        accessor: "position",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Position {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Date Modified",
        accessor: "date_modified",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  //NOTIFICATION
  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USER });
    toast(user.message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USER_ERROR });
    toast(user.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  React.useEffect(() => {
    user.isUserSubmitted && handleNotification();

    user.error &&
      user.errorMessage &&
      user.status != 500 &&
      user.status != 403 &&
      handleErrorNotification();
  }, [user]);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_USER_LIST,
    });
    props.actionCreator({
      type: types.GET_USERGROUP_LIST,
    });
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setSelectedUser({
      id: user.id,
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      position: user.position,
      user_group_id: user.user_group_id,
      is_active: user.is_active,
    });

    setIsEdit(true);

    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete user modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (user) => {
    setSelectedUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    props.actionCreator({
      type: types.DELETE_USER,
      payload: {
        id: selectedUser.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setSelectedUser({});
    setIsEdit(false);
    toggle();
  };

  //meta title
  document.title = "User | RFID";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={user}
        statusCode={user.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={user.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User" breadcrumbItem="User" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      User List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({user.data.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-light"
                        onClick={handleUserClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                        tag="a"
                      >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                    </UncontrolledDropdown> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={user.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "New User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Firstname</Label>
                              <Input
                                name="firstname"
                                label="Firstname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstname || ""}
                                invalid={
                                  validation.touched.firstname &&
                                  validation.errors.firstname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstname &&
                              validation.errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Lastname</Label>
                              <Input
                                name="lastname"
                                label="Lastname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastname || ""}
                                invalid={
                                  validation.touched.lastname &&
                                  validation.errors.lastname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastname &&
                              validation.errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Position</Label>
                              <Input
                                name="position"
                                label="Position"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.position || ""}
                                invalid={
                                  validation.touched.position &&
                                  validation.errors.position
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Username</Label>
                              <Input
                                name="username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                  validation.errors.username
                                    ? true
                                    : false
                                }
                                autoComplete="username"
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div>
                              <Label className="form-label">Password</Label>
                              <div className="mb-3 input-group">
                                <Input
                                  name="password"
                                  label="Password"
                                  type={passwordShow ? "text" : "password"}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                  autoComplete="new-password"
                                />
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light shadow-none ms-0"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i
                                    className={`mdi ${!passwordShow ? "mdi-eye-off-outline" : "mdi-eye-outline"}`}
                                  ></i>
                                </button>
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">User Group</Label>
                              <Input
                                type="select"
                                name="user_group_id"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_group_id || ""}
                                invalid={
                                  validation.touched.user_group_id &&
                                  validation.errors.user_group_id
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>
                                  Select Current User Group
                                </option>
                                {usergroup.list.data
                                  .filter((uglist) => uglist.status === 1)
                                  .map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Input>
                              {validation.touched.user_group_id &&
                              validation.errors.user_group_id ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.user_group_id}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                type="select"
                                name="is_active"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.is_active || "0"}
                                invalid={
                                  validation.touched.is_active &&
                                  validation.errors.is_active
                                    ? true
                                    : false
                                }
                              >
                                <option value={""}>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </Input>
                              {validation.touched.is_active &&
                              validation.errors.is_active ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.is_active}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, usergroup, authentication }) => ({
  user,
  usergroup,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(User);
