import { call, put, takeLatest } from "redux-saga/effects";

import {
  CHECK_TAGS_MISMATCH,
  CHECK_TAGS_MISMATCH_SUCCESS,
  CHECK_TAGS_MISMATCH_FAILED,
} from "./types";

import tagServices from "../../api/services/tag";

import { handleErrorResponse } from "../../helpers/api_helper"

function* fnCheckTagMismatch({ payload }) {
  const { serial_number } = payload;
  try {
    const response = yield call(tagServices.api.fnCheckTagMismatch, {
      serial_number,
    });

    yield put({
      type: CHECK_TAGS_MISMATCH_SUCCESS,
      payload: {
        ...response.data.result,
      },
    });
  } catch (error) {
    yield put({
      type: CHECK_TAGS_MISMATCH_FAILED,
      payload: setServerErrorResponse(error),
    });
  }
}


export const setServerErrorResponse = (error) =>
  error.response !== undefined
    ? error.response.data
    : {
        result: false,
        message: "Oops, something went wrong. Please try again later.",
      };

export default function* watcher() {
  yield takeLatest(CHECK_TAGS_MISMATCH, fnCheckTagMismatch);
}
