import axios from "axios";
//import { store, types } from "ducks";
import { useLocation } from "react-router-dom";
import { store } from "../store";

import { API_ERROR, LOGOUT_USER } from "../store/authentication/types";
/**
 * ===================================
 * START OF AXIOS CONFIGURATION
 * ===================================
 */
const rfidApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 50000,
});

/**
 * ===================================
 * END OF AXIOS CONFIGURATION
 * ===================================
 */

// interceptor this is for the token
rfidApi.interceptors.request.use(
  async (config) => {
    const { authentication } = store.getState();
    if (window.location.pathname === "/reset-password") {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      config.headers.authorization = `${token}`;
    } else if (authentication.token) {
      config.headers.authorization = `${authentication.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor to be updated depends on requirements
rfidApi.interceptors.response.use(
  async (config) => {
    const { authentication } = store.getState();
    if (authentication.token) {
      config.headers = {
        Authorization: `${authentication.token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };
    }

    return config;
  },
  async function (error) {
    if (!error.response) {
      // Network or server error
      console.error('Network/Server error', error);
    } else if (error.response.status === 500 || error.response.status === 403) {
      console.error('Invalid.', error);
    }
    return Promise.reject(error);
  }
);

export default rfidApi;
