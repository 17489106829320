import rfidApi from "../../api";
import { APP_SERVICE } from "../../api/constants";

class App {
  fnGetMenus = async () => {
    return await rfidApi.get(APP_SERVICE.MENU);
  };
}

App.api = new App();
export default App;