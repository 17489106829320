import { takeEvery, takeLatest, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { 
  types
} from "../../../store";

import authenticationServices from "../../../api/services/authentication";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetPassword({ payload: { email } }) {
  try {
    const response = yield call(authenticationServices.api.fnForgetPassword, {
      email,
    });
    if (response) {
      yield put({
        type: types.FORGET_PASSWORD_SUCCESS,
        payload: { data: "An email with the reset link has been sent to you. Please check your inbox."},
      });
    }
  } catch (error) {
    yield put({
      type: types.FORGET_PASSWORD_ERROR,
      payload: { error: error.response?.data.message },
    });
  }
}

function* resetPassword({ payload: { password } }) {
  try {
    const response = yield call(authenticationServices.api.fnResetPassword, {
      password,
    });
    if (response) {
      yield put({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: { data: "Your password has been changed. You will be redirected to the login page shortly..."},
      });
    }
  } catch (error) {
    let errorMessage = "";
    if (error.message === "Network Error") {
      errorMessage = "Server connection failed."
    } else {
      errorMessage = error.response?.data.message;
    }
    yield put({
      type: types.RESET_PASSWORD_ERROR,
      payload: { error: errorMessage },
    });
  }
}

export function* watchUserPasswordForget() {
  yield takeLatest(types.FORGET_PASSWORD, forgetPassword)
  yield takeLatest(types.RESET_PASSWORD, resetPassword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
