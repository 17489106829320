import React from "react";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

const Identifier = (cell) => {
  return (
    <React.Fragment>
      <Link
        to="#"
        className="badge bg-primary-subtle text-primary font-size-11 m-1"
        key={"_skill_" + cell.id}
      >
        {cell.value}
      </Link>
    </React.Fragment>
  );
};

const Status = (cell) => {
  return cell.value === 1 ? "Active" : "Inactive";
};

const Date = (cell) => {
  return cell.value ? cell.value : "";
};

export { Status, Identifier, Date };
