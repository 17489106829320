import rfidApi from "..";
import { EQUIPMENT_SERVICE } from "../constants";

class Equipment {
  async fnGetEquipmentList() {
    return await rfidApi.get(EQUIPMENT_SERVICE.GET_EQUIPMENT_LIST);
  }

  async fnAddNewEquipment(values) {
    return await rfidApi.post(EQUIPMENT_SERVICE.ADD_NEW_EQUIPMENT, values);
  }

  async fnUpdateEquipment(values) {
    return await rfidApi.post(
      `${EQUIPMENT_SERVICE.UPDATE_EQUIPMENT}/${values.id}`,
      values
    );
  }

  async fnDeleteEquipment(id) {
    return await rfidApi.delete(`${EQUIPMENT_SERVICE.DELETE_EQUIPMENT}/${id}`);
  }
}

Equipment.api = new Equipment();
export default Equipment;
