import rfidApi from "..";
import { USERGROUP_SERVICE } from "../constants";

class Usergroup {
  fnGetUserGroup = async (values) => {
    const payload = {
      display: values.display,
      page: values.page,
    };
    return await rfidApi.post(USERGROUP_SERVICE.GET_USERGROUP, payload);
  };

  fnGetUserGroupList = async () => {
    return await rfidApi.get(USERGROUP_SERVICE.GET_USERGROUP_LIST);
  };

  fnGetUserGroupByID = async (values) => {
    return await rfidApi.get(
      `${USERGROUP_SERVICE.GET_SELECTED_USERGROUP}/${values.id}`
    );
  };

  fnPostUserGroup = async (payload) => {
    return await rfidApi.post(USERGROUP_SERVICE.POST_USERGROUP_SUBMIT, payload);
  };

  fnAddNewUserGroup = async (values) => {
    return await rfidApi.post(USERGROUP_SERVICE.ADD_NEW_USERGROUP, values);
  };

  fnUpdateUserGroup = async (values) => {
    return await rfidApi.patch(
      `${USERGROUP_SERVICE.UPDATE_USERGROUP}/${values.id}`,
      values
    );
  };

  fnDeleteUserGroup = async (id) => {
    return await rfidApi.delete(`${USERGROUP_SERVICE.DELETE_USERGROUP}/${id}`);
  };
}

Usergroup.api = new Usergroup();
export default Usergroup;