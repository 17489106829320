import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import authenticationSaga from "./authentication/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
//import dashboard
import dashBoardSaga from "./dashboard/saga";
import commissionSaga from "./commission/saga";
import locationsSaga from "./locations/saga";
import equipmentSaga from "./equipment/saga";
import auditSaga from "./audit/saga";
import tagSaga from "./tag/saga";
import userSaga from "./user/saga";
import usergroupSaga from "./user-group/saga";
import appSaga from "./app/saga";

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(authenticationSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashBoardSaga),
    fork(commissionSaga),
    fork(locationsSaga),
    fork(equipmentSaga),
    fork(auditSaga),
    fork(tagSaga),
    fork(userSaga),
    fork(usergroupSaga),
    fork(appSaga),
  ]);
}
