import {
  GET_SERIAL_LOGS_SUCCESS,
  GET_SERIAL_LOGS_FAILED,
  GET_AUDIT_TRAIL_SUCCESS,
  GET_AUDIT_TRAIL_FAILED,
  CLEAR_SERIAL_AUDIT_TRAIL
} from "./types";

const INIT_STATE = {
  serialLogs: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  auditLogs: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
};

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERIAL_LOGS_SUCCESS:
      return {
        ...state,
        serialLogs: {
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_SERIAL_LOGS_FAILED:
      return {
        ...state,
        serialLogs: {
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        auditLogs: {
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_AUDIT_TRAIL_FAILED:
      return {
        ...state,
        auditLogs: {
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case CLEAR_SERIAL_AUDIT_TRAIL:
      return INIT_STATE;

    default:
      return state;
  }
};

export default dashboard;
