import { combineReducers } from "redux";

// Front
import layout from "./layout/reducer";

// Authentication
import authentication from "./authentication/reducer";
import account from "./auth/register/reducer";
import forgetPassword from "./auth/forgetpwd/reducer";
import profile from "./auth/profile/reducer";

import dashboard from "./dashboard/reducer";
import commission from "./commission/reducer";
import locations from "./locations/reducer";

import equipment from "./equipment/reducer";
import audit from "./audit/reducer";
import tag from "./tag/reducer";
import user from "./user/reducer";
import usergroup from "./user-group/reducer";
import app from "./app/reducer";

const rootReducer = combineReducers({
  layout,
  authentication,
  account,
  forgetPassword,
  profile,
  dashboard,
  commission,
  locations,
  equipment,
  audit,
  tag,
  user,
  usergroup,
  app,
});

export default rootReducer;
