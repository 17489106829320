import React from "react";
import { Button, Tooltip } from "reactstrap";
import StyledText from "../../components/Common/StyledText";
import "./customStyles.css";

const SerialNumbers = ({ item }) => {
  const [numItemsToShow, setNumItemsToShow] = React.useState(10);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [dynamicText, setDynamicText] = React.useState();
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleLoadMore = (loadNumber) => {
    setNumItemsToShow((prevNumItems) => prevNumItems + loadNumber);
  };

  return (
    <>
      {item.serials.slice(0, numItemsToShow).map((serial, index) => (
        <StyledText
          size={11}
          key={index}
          className={`${serial.has_missing_tags ? "missing-tags" : ""}`}
        >
          {/* Sar21 -  */}
          {serial.has_missing_tags && (
            <>
              <p
                className="mb-0 rfid-tags-label"
                id="TooltipTBarrel"
                style={{
                  cursor: "pointer",
                }}
              >
                {serial.serial_number}
              </p>
            </>
          )}

          {!serial.has_missing_tags && serial.serial_number}
        </StyledText>
      ))}

      {item.serials.length > numItemsToShow && (
        <Button
          type="button"
          color="light"
          style={{
            borderRadius: 20,
            width: 80,
            height: 35,
            padding: 0,
            alignSelf: "center",
          }}
          onClick={() => {
            handleLoadMore(item.serials.length - numItemsToShow);
          }}
        >
          <i className="bx bx-down-arrow-alt font-size-24 align-middle"></i>+
          {item.serials.length - numItemsToShow}
        </Button>
      )}
    </>
  );
};

export default SerialNumbers;
