import { call, put, takeLatest } from "redux-saga/effects";

import { handleErrorResponse } from "../../helpers/api_helper"
import {
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILED,
  ADD_NEW_LOCATION,
  ADD_NEW_LOCATION_SUCCESS,
  ADD_NEW_LOCATION_FAILED,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILED,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILED,
} from "./types";

import locationServices from "../../api/services/locations";

function* fnGetLocationList() {
  try {
    const data = yield call(locationServices.api.fnGetLocationList);

    yield put({
      type: GET_LOCATION_LIST_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_LOCATION_LIST_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnAddNewLocation({ payload }) {
  try {
    const result = yield call(locationServices.api.fnAddNewLocation, payload);

    if (result) {
      yield put({
        type: ADD_NEW_LOCATION_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_LOCATION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_NEW_LOCATION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnUpdateLocation({ payload }) {
  try {
    const result = yield call(locationServices.api.fnUpdateLocation, payload);

    if (result) {
      yield put({
        type: UPDATE_LOCATION_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_LOCATION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_LOCATION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnDeleteLocation({ payload }) {
  try {
    const { id } = payload;

    const result = yield call(locationServices.api.fnDeleteLocation, id);

    if (result) {
      yield put({
        type: DELETE_LOCATION_SUCCESS,
        payload: { message: result.data.message },
      });

      yield put({
        type: GET_LOCATION_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_LOCATION_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_LOCATION_LIST, fnGetLocationList);
  yield takeLatest(ADD_NEW_LOCATION, fnAddNewLocation);
  yield takeLatest(UPDATE_LOCATION, fnUpdateLocation);
  yield takeLatest(DELETE_LOCATION, fnDeleteLocation);
}
