import rfidApi from "../";
import { DASHBOARD_SERVICE } from "../../api/constants";

class Dashboard {
  async fnGetSerialLogs() {
    return await rfidApi.get(DASHBOARD_SERVICE.GET_SERIAL_LOGS);
  }

  async fnGetAuditTrail(values) {
    return await rfidApi.get(
      `${DASHBOARD_SERVICE.GET_AUDIT_TRAILS}/${values.limit}`
    );
  }
}

Dashboard.api = new Dashboard();
export default Dashboard;
