import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import { handleErrorResponse } from "../../helpers/api_helper"

// Crypto Redux States
import {
  GET_SERIAL_LOGS,
  GET_SERIAL_LOGS_SUCCESS,
  GET_SERIAL_LOGS_FAILED,
  GET_AUDIT_TRAIL,
  GET_AUDIT_TRAIL_SUCCESS,
  GET_AUDIT_TRAIL_FAILED,
} from "./types";

import dashboardServices from "../../api/services/dashboard";

function* fnGetSerialLogs() {
  try {
    const data = yield call(dashboardServices.api.fnGetSerialLogs);
    yield put({
      type: GET_SERIAL_LOGS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_SERIAL_LOGS_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

function* fnGetAuditTrail({ payload }) {
  try {
    const data = yield call(dashboardServices.api.fnGetAuditTrail, payload);
    yield put({
      type: GET_AUDIT_TRAIL_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_AUDIT_TRAIL_FAILED,
      ...handleErrorResponse(error)
    });
  }
}

export function* watchGetSerialLogsData() {
  yield takeLatest(GET_SERIAL_LOGS, fnGetSerialLogs);
  yield takeLatest(GET_AUDIT_TRAIL, fnGetAuditTrail);
}

function* dashboardSaga() {
  yield all([fork(watchGetSerialLogsData)]);
}

export default dashboardSaga;
