import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { 
  types
} from "../../store";

import authenticationServices from "../../api/services/authentication";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(authenticationServices.api.fnLogin, {
      username: user.username,
      password: user.password,
    });

    localStorage.setItem("authUser", JSON.stringify(response));
    yield put({
      type: types.LOGIN_SUCCESS,
      payload: { token: response.data.token, data: response.data },
    });
    history("/dashboard");
  } catch (error) {
    yield put({
      type: types.LOGIN_FAILED,
      payload: { error },
    });
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    yield put({
      type: types.LOGOUT_USER_SUCCESS,
    });
    yield put({ type: types.CLEAR_USERGROUP });
    yield put({ type: types.CLEAR_USER });
    yield put({ type: types.CLEAR_SERIAL_AUDIT_TRAIL });
    yield put({ type: types.CLEAR_EQUIPMENT });
    yield put({ type: types.CLEAR_COMMISSION });

    history("/login");
  } catch (error) {
    yield put({
      type: types.API_ERROR,
      payload: { error },
    });
  }
}

function* authenticationSaga() {
  yield takeLatest(types.LOGIN_USER, loginUser);
  yield takeLatest(types.LOGOUT_USER, logoutUser);
}

export default authenticationSaga;
