export const GET_LOCATION_LIST = "@locations/GET_LOCATION_LIST";
export const GET_LOCATION_LIST_SUCCESS = "@locations/GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILED = "@locations/GET_LOCATION_LIST_FAILED";

export const ADD_NEW_LOCATION = "@locations/ADD_NEW_LOCATION";
export const ADD_NEW_LOCATION_SUCCESS = "@locations/ADD_NEW_LOCATION_SUCCESS";
export const ADD_NEW_LOCATION_FAILED = "@locations/ADD_NEW_LOCATION_FAILED";

export const UPDATE_LOCATION = "@locations/UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "@commission/UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILED = "@locations/UPDATE_LOCATION_FAILED";

export const DELETE_LOCATION = "@locations/DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "@locations/DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILED = "@locations/DELETE_LOCATION_FAILED";
export const CLEAR_LOCATION = "@locations/CLEAR_LOCATION";
export const CLEAR_LOCATION_ERROR = "@locations/CLEAR_LOCATION_ERROR";
