export const GET_AUDIT_TRAIL_LIST = "@audit/GET_AUDIT_TRAIL_LIST";
export const GET_AUDIT_TRAIL_LIST_SUCCESS =
  "@audit/GET_AUDIT_TRAIL_LIST_SUCCESS";
export const GET_AUDIT_TRAIL_LIST_FAILED = "@audit/GET_AUDIT_TRAIL_LIST_FAILED";

export const EXPORT_AUDIT = "@audit/EXPORT_AUDIT";
export const EXPORT_AUDIT_SUCCESS = 
  "@audit/EXPORT_AUDIT_SUCCESS";
export const EXPORT_AUDIT_FAILED = "@audit/EXPORT_AUDIT_FAILED";

