import React, { useState } from "react";
import { connect } from "react-redux";

import {
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CLEAR_FORGET_PASSWORD_ERROR
} from "../../store/auth/forgetpwd/types";

import { actionCreator } from "../../store";

import {
  Row,
  Col,
  Alert,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import stEngrLogo from "../../assets/images/ste-logo.png";

const ResetPassword = ({
  forgetPassword,
  authentication,
  location,
  navigate,
  params,
  ...props
}) => {

  //meta title
  document.title = "Reset Password | RFID";

  const [passwordShow, setPasswordShow] = useState(false);
  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/;
  let navigateLoc = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(passwordRules, {
          message:
            "Must contain 10 Characters, one Uppercase, one Lowercase, one Number and one Special Character",
        })
        .matches(/^\S*$/, "White Spaces are not allowed")
        .required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      props.actionCreator({
        type: RESET_PASSWORD,
        payload: {
          password: values.password,
        },
      });
    },
  });

  React.useEffect(() => {
    if (forgetPassword.resetSuccessMsg) {
      setTimeout(() => {
        props.actionCreator({
          type: CLEAR_FORGET_PASSWORD_ERROR
        });
        navigateLoc("/login");
      }, 7000);
    }
  }, [forgetPassword]);

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3 mx-auto">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={stEngrLogo} alt="" height="28" />{" "}
                        <span className="logo-txt">RFID</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                      </div>

                      {forgetPassword.resetError &&
                      forgetPassword.resetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {forgetPassword.resetError.error}
                        </Alert>
                      ) : null}

                      {forgetPassword.resetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetPassword.resetSuccessMsg.data}
                        </Alert>
                      ) : null}

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3 input-group">
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type={passwordShow ? "text" : "password"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setPasswordShow(!passwordShow)}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                            id="password-addon"
                          >
                            <i className={`mdi ${!passwordShow ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}></i>
                          </button>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ST Engineering.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ forgetPassword, authentication }) => ({ forgetPassword, authentication });

export default withRouter(
  connect(mapStateToProps, { actionCreator })(ResetPassword)
);
