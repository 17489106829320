import {
  GET_COMMISSION_LIST_SUCCESS,
  GET_COMMISSION_LIST_FAILED,
  ADD_NEW_COMMISSION_SUCCESS,
  ADD_NEW_COMMISSION_FAILED,
  UPDATE_COMMISSION_SUCCESS,
  UPDATE_COMMISSION_FAILED,
  DELETE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_FAILED,
  CLEAR_COMMISSION,
  CLEAR_COMMISSION_ERROR_MESSAGE,
  EXPORT_COMMISSION_SUCCESS,
  EXPORT_COMMISSION_FAILED
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
  status: null,
  isCommissionSubmitted: false,
};

const commission = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMISSION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        status: null,
      };

    case GET_COMMISSION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        status: action.status,
      };

    case ADD_NEW_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isCommissionSubmitted: true,
        message: action.payload.message,
      };

    case ADD_NEW_COMMISSION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isCommissionSubmitted: false,
      };

    case UPDATE_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isCommissionSubmitted: true,
        message: action.payload.message,
      };

    case UPDATE_COMMISSION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isCommissionSubmitted: false,
      };

    case DELETE_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isCommissionSubmitted: true,
        message: action.payload.message,
      };

    case DELETE_COMMISSION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isCommissionSubmitted: false,
      };

    case EXPORT_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        message: action.payload.message,
        isCommissionSubmitted: false,
      };

    case EXPORT_COMMISSION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isCommissionSubmitted: false,
      };

    case CLEAR_COMMISSION:
      return INIT_STATE;
    case CLEAR_COMMISSION_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        status: null,
      };
    default:
      return state;
  }
};

export default commission;
