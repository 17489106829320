import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import SerialNumbers from "./SerialNumbers";

const SerialLiveLocation = ({ liveSerialLogsSource }) => {
  return (
    <React.Fragment>
      <div
        className="col-md-9"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {liveSerialLogsSource.map((item, index) => (
          <Card
            key={index}
            style={{
              width: "20%",
              height: "100%",
              marginRight: 15,
              borderColor: "#E1DFDC",
            }}
          >
            <CardHeader>
              <h4 className="card-title">{item.location_name}</h4>
              <p className="card-title-desc">
                <code
                  style={{
                    fontSize: 26,
                    fontWeight: 700,
                  }}
                >
                  {item.serials.length}
                </code>
              </p>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SerialNumbers item={item} />
            </CardBody>
          </Card>
        ))}
      </div>
    </React.Fragment>
  );
};

export default SerialLiveLocation;
