import React from "react";
import { Navigate } from "react-router-dom";

//Utility
import PageMaintenance from "../pages/Utility/PageMaintenance";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import SoloDashboard from "../pages/Utility/SoloDashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import UserProfile from "../pages/Authentication/user-profile";
import RFIDDashboard from "../pages/RFIDDashboard";
import Commission from "../pages/Commission";
import Equipment from "../pages/Equipment";

import User from "../pages/User";
import UserGroup from "../pages/UserGroup";
import Locations from "../pages/Locations";
import AuditTrail from "../pages/AuditTrail";
import ResetPassword from "../pages/Authentication/ResetPassword";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: <RFIDDashboard /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  // RFID Screens
  { path: "/commission", component: <Commission /> },
  { path: "/equipment", component: <Equipment /> },
  { path: "/user", component: <User /> },
  { path: "/user-group", component: <UserGroup /> },
  { path: "/locations", component: <Locations /> },
  { path: "/audit-trail", component: <AuditTrail /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/page-login", component: <PageLogin /> },
  { path: "/page-register", component: <PageRegister /> },
  { path: "/page-recoverpw", component: <RecoverPassword /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/page-logout", component: <AuthLogout /> },

  //Utility page
  { path: "/pages-maintenance", component: <PageMaintenance /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/", exact: true, component: <Navigate to="/login" /> },
  //custom page
  { path: "/solo-dashboard", component: <SoloDashboard /> },
];

export { userRoutes, authRoutes };
