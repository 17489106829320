import rfidApi from "..";
import { COMMISSION_SERVICE } from "../constants";

class Commission {
  async fnGetCommissionList() {
    return await rfidApi.get(COMMISSION_SERVICE.GET_COMMISSION_LIST);
  }

  async fnAddNewCommission(values) {
    return await rfidApi.post(COMMISSION_SERVICE.ADD_NEW_COMMISSION, values);
  }

  async fnUpdateCommission(values) {
    return await rfidApi.patch(
      `${COMMISSION_SERVICE.UPDATE_COMMISSION}/${values.id}`,
      values
    );
  }

  async fnDeleteCommission(id) {
    return await rfidApi.delete(
      `${COMMISSION_SERVICE.DELETE_COMMISSION}/${id}`
    );
  }

  async fnExportCommission() {
    return await rfidApi.get(
      `${COMMISSION_SERVICE.EXPORT_COMMISSION}`
    );
  }
}

Commission.api = new Commission();
export default Commission;
