import React from "react";
import { Button } from "reactstrap";
import StyledText from "../../../components/Common/StyledText";

const SerialNumbers = ({ item }) => {
  const [numItemsToShow, setNumItemsToShow] = React.useState(10);

  const handleLoadMore = (loadNumber) => {
    setNumItemsToShow((prevNumItems) => prevNumItems + loadNumber);
  };

  return (
    <>
      {item.serials.slice(0, numItemsToShow).map((serial, index) => (
        <StyledText size={11} key={index}>
          Sar21 - {serial.serial_number}
        </StyledText>
      ))}

      {item.serials.length > numItemsToShow && (
        <Button
          type="button"
          color="light"
          style={{
            borderRadius: 20,
            width: 80,
            height: 35,
            padding: 0,
            alignSelf: "center",
          }}
          onClick={() => {
            handleLoadMore(item.serials.length - numItemsToShow);
          }}
        >
          <i className="bx bx-down-arrow-alt font-size-24 align-middle"></i>+
          {item.serials.length - numItemsToShow}
        </Button>
      )}
    </>
  );
};

export default SerialNumbers;
