import rfidApi from "..";
import { LOCATION_SERVICE } from "../constants";

class Locations {
  async fnGetLocationList() {
    return await rfidApi.get(LOCATION_SERVICE.GET_LOCATION_LIST);
  }

  async fnAddNewLocation(values) {
    return await rfidApi.post(LOCATION_SERVICE.ADD_NEW_LOCATION, values);
  }

  async fnUpdateLocation(values) {
    return await rfidApi.patch(
      `${LOCATION_SERVICE.UPDATE_LOCATION}/${values.id}`,
      values
    );
  }

  async fnDeleteLocation(id) {
    return await rfidApi.delete(`${LOCATION_SERVICE.DELETE_LOCATION}/${id}`);
  }
}

Locations.api = new Locations();
export default Locations;
