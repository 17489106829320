import rfidApi from "..";
import { DASHBOARD_SERVICE } from "../constants";

class AuditTrail {
  async fnGetAuditTrailList(values) {
    const payload = {
      display: values.display,
      page: values.page,
    };

    return await rfidApi.post(DASHBOARD_SERVICE.GET_AUDIT_TRAIL_LIST, payload);
  }

  async fnExportAudit(values) {
    const payload = { values };
    return await rfidApi.post(DASHBOARD_SERVICE.EXPORT_AUDIT, payload);
  }

}

AuditTrail.api = new AuditTrail();
export default AuditTrail;
